import React from "react"

import Container from "../container"
import { StaticImage } from "gatsby-plugin-image"
import "./video.scss"

interface VideoProps {
    invert?: boolean
}

const Video = ({invert}: VideoProps) => {
  return (
    <div id="video-anmeldelse" className={invert ? "video video--inverted" : "video"}>
      <Container>
        <div className="video__inner">
          <h2>Genforeningnen blev dyr.</h2>
          <h4>Se kulturredaktør Hans Christian Davidsens anmeldelse af "Hjælpen til Sønderjylland".</h4>
          <div className="video__wrapper">
            <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/z5dvLAHRnvg?si=4XdMaYX3AAcMvcVg"
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
            ></iframe>
          </div>
          
        </div>
      </Container>
    </div>
  )
}
export default Video;