import React from "react"
import { Link } from "gatsby"

import "./authorIntro.scss"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../container"

interface AuthorProps {
  invert?: boolean;
}
const Author = ({invert}: AuthorProps) => {
  return (
    <div className={`author${invert ? ' author--inverted' : ''}`}>
      <Container>
        <div className="author__inner">
          <div className="author__image">
            <StaticImage src="../../images/niels_jensen.jpg" alt="Niels Jensen - Forfatter og foredragsholder"/>
          </div>
          <div className="author__text">
            <h2>Forfatter og foredragsholder</h2>
            <p>Niels Jensen har en fortid som taktisk efterretningsofficer i det danske forsvar, og har arbejdet som dagbladsjournalist og forfatter i mere end 40 år.</p>
            <p>Han har skrevet mere end 40 bøger non-fiction og er nu foredragsholder om Danmarks nyere historie - især Den kolde Krig.</p>
            <div>
              <Link 
                to="/om"
                className={`btn${invert ? ' btn--dark' : ''}`}
                >
                Læs mere...
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Author;
