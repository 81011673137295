import * as React from "react"
import { HeadFC, useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Video from "../components/video"
import Author from "../components/authorIntro"
import Values from "../components/values"
import Carousel from "../components/carousel"
import Boeger from "../components/boeger"
import Book from "../components/book"
import Kontakt from "../components/kontakt"
import { SEO } from "../components/seo"
import HistoriskeDage from "../components/historiskeDage"


const intro = {
  heading: "Velkommen til <span>Niels Jensens</span> hjemmeside.",
  description: "<h2>Journalist, forfatter og foredragsholder.</h2>"
}


const IndexPage = () => {
  // const today = new Date();
  // const eventDatePassed = new Date('2023-03-18');

  // const eventIsUpcoming = (firstDate:any, secondDate:any) =>
  //   firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0);

  // const eventForbi = eventIsUpcoming(eventDatePassed, today)

  return (
    <Layout>
      <Intro heading={intro.heading} description={intro.description}/>
      <Author invert/>
      {/* <HistoriskeDage/> */}
      <Video invert/>
      <Values/>
      <Boeger invert/>
      <Carousel bg/>
      <Book/>
      <Kontakt/>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  return (
    <SEO 
      title={`Velkommen til Niels Jensens hjemmeside. | ${site.siteMetadata.title}`} 
      description={site.siteMetadata.description}
    />
  )
}

