import React from 'react';
import {Link} from 'gatsby';

import './values.scss'

// interface ValuesProps {
  
// }
const Values = () => {
  return (
    <div className="values">
      <div className="cols">
        <div className="col">
          <div className="sticky-container">
            <div className="section-separator">
              <div className="section-separator__line"></div>
              <span className="section-separator__text">Niels tilbyder</span>
            </div>
            <h2 className="scrolling-anchors__title">
              Spændende foredrag
            </h2>
            <p className="scrolling-anchors__subtext">
              om sine bøger - eller om emner på tværs af bøgerne - til museer, biblioteker og foreninger:
            </p>
          </div>
        </div>
        <div className="col">
          {/* INSERT NEW ITEMS HERE */}

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Fæstningen der reddede Danmark fra Den 1. Verdenskrig"</h3>
              <h4>Foredrag om Danmarkshistoriens største anlægsarbejde, der sikrede Danmark mod inddragelse i Den 1. Verdenskrig.</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Mulighed for at købe Niels Jensens seneste bøger.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter.
              </p>
              <p>
                <Link 
                  to="/foredrag#0"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Flugten over Østersøen"</h3>
              <h4>Foredrag om de tusinder af DDR-borgere, der risikerede livet ved at flygte over Østersøen - og de få det lykkedes for…</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Mulighed for at købe Niels Jensens seneste bøger.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter.
              </p>
              <p>
                <Link 
                  to="/foredrag#1"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>
          
          
          
          
          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Stay behind"</h3>
              <h4>Myter og fakta om NATO's Okkupationsberedskab Under Den kolde Krig 1945 - 1990</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Fremvisining af diverse kort og forhenværende "hemmelige" filer.</li>
                <li>Mulighed for at købe Niels Jensens seneste bøger.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter.
              </p>
              <p>
                <Link 
                  to="/foredrag#2"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Prisen for Sønderjylland"</h3>
              <h4>Foredraget bygger på bogen "Hjælpen til Sønderjylland 1920-1940" som bliver udgivet i april 2023 af Forlaget Frydenlund.</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Mulighed for at købe bogen "Hjælpen til Sønderjylland 1920-1940" udgivet april 2023.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter. Der er rig mulighed for tilhørerne til at stille spørgsmål.
              </p>
              <p>
                <Link 
                  to="/foredrag#3"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Ivan - og Putin - ved hvor du bor"</h3>
              <h4>Foredrag om de østeuropæiske angrebsplaner mod Danmark og Danmarks geostrategiske position som "prop" i adgangen til Østersøen.</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Udstilling af hemmelige kort, hemmelige planer, fotografier, håndbøger og andet i relation til foredraget.</li>
                <li>Mulighed for at købe hemmelige russiske kort over udvalgte dele af Danmark - i alle målestoksforhold.</li>
                <li>Mulighed for at købe bogen "Den hemmelige Krig" udgivet november 2022.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter. Der er rig mulighed for tilhørerne til at stille spørgsmål.
              </p>
              <p>
                <Link 
                  to="/foredrag#4"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"For Dannebrogs Ære"</h3>
              <h4>Foredrag om de 200 danske Lejesoldater, der kæmpede mod russiske bolsjevikker i Baltikums frihedskamp 1919.</h4>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>udstilling af kort, planer, billeder og dokumenter i relation til Dansk-Baltisk Auxiliær Corps, der deltog i hårde kampe mod bolsjevikiske styrker i de baltiske landes frihedskrig i 1919.</li>
                {/* <li>Mulighed for at købe hemmelige russiske kort over udvalgte dele af Danmark - i alle målestoksforhold.</li> */}
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter. Der er rig mulighed for tilhørerne til at stille spørgsmål.
              </p>
              <p>
                <Link 
                  to="/foredrag#5"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>

          <div className="scrolling-anchors__anchor">
            <div className="scrolling-anchors__header">
              <h3 className="scrolling-anchors__heading">"Brændpunkt Østersøen under Den Kolde Krig - og nu..."</h3>
              <h4>Den nye hybridkrig og angrebet på gasledningerne.</h4>
              <p>Danmarks beliggenhed som Østersøens Lås har gennem århundreder været - og er stadig et sikkerhedsproblem for kongeriget Danmark. Med englænderne, nederlænderne og amerikanerne i vest - og russerne, svenskerne, hansestæderne og tyskerne i øst - ligger Danmark i en udsat position.</p>
            </div>
            <div className="scrolling-anchors__description">
              <h4>
                Foredraget inkluderer:
              </h4>
              <ul>
                <li>Powerpoint præsentation.</li>
                <li>Udstilling af kort, planer, billeder og dokumenter.</li>
              </ul>
              <p className="">
                <b>Varighed:</b> 2 timer incl. en pause på ca. 15 minutter. Der er rig mulighed for tilhørerne til at stille spørgsmål.
              </p>
              <p>
                <Link 
                  to="/foredrag#6"
                  className="btn"
                  >
                  Læs mere...
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Values;